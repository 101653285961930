<template>
    <main>
        <page-header-compact>Configuración - rangos Escala</page-header-compact>
        <div class="container-xl px-4 mt-n10">
            <div class="card">
                <div class="card-body">
                    <div class="col-12 d-flex justify-content-end py-4">
                        <button @click="modificar=false; abrirModal();" type="button" class="btn btn-primary ">
                            Crear rango&nbsp;<i class="fas fa-plus"></i>
                        </button>
                    </div>
                    <div class="modal fade" id="modalRango" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{{tituloModal}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form >
                                        <div class="form-group">
                                            <label class="form-label">Nombre</label>
                                            <input type="text" class="form-control" v-model="rango.nombre"  :class="{'is-invalid':$v.rango.nombre.$error && $v.rango.nombre.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Edad Inicio</label>
                                            <input type="number" class="form-control" v-model="rango.edad_inicio"  :class="{'is-invalid':$v.rango.edad_inicio.$error && $v.rango.edad_inicio.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Tipo Edad Inicio</label>
                                            <select  v-model="rango.tipo_edad_inicio"   class="form-control" :class="{'is-invalid':$v.rango.tipo_edad_inicio.$error && $v.rango.tipo_edad_inicio.$dirty}" >
                                                <option value="A">A</option>
                                                <option value="M">M</option>
                                                <option value="D">D</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Edad Final</label>
                                            <input type="number" class="form-control" v-model="rango.edad_final"  :class="{'is-invalid':$v.rango.edad_final.$error && $v.rango.edad_final.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Tipo Edad Final</label>
                                            <select  v-model="rango.tipo_edad_final"   class="form-control" :class="{'is-invalid':$v.rango.tipo_edad_final.$error && $v.rango.tipo_edad_final.$dirty}" >
                                                <option value="A">A</option>
                                                <option value="M">M</option>
                                                <option value="D">D</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-success" @click="guardar()">
                                        <i class="fas fa-save"></i>&nbsp;Guardar
                                     </button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table table-responsive">
                        <table class="table table-striped table-bordered">
                            <tr class="table-primary">
                                <th>Nombre</th>
                                <th>Edad Inicio</th>
                                <th>Tipo Edad Inicio</th>
                                <th>Edad Final</th>
                                <th>Tipo Edad Final</th>
                                <th>Opciones</th>
                            </tr>
                            <tr v-for="item in rangos" :key="item.id">
                                <td>{{item.nombre}}</td>
                                <td>{{item.edad_inicio}}</td>
                                <td>{{item.tipo_edad_inicio}}</td>
                                <td>{{item.edad_final}}</td>
                                <td>{{item.tipo_edad_final}}</td>
                                <td >
                                   <div class="form-row">
                                        <div class="form-group px-2">
                                            <button @click="modificar=true; abrirModal(item);" class="btn btn-outline-primary lift" >
                                                <i class="fa fa-edit"></i>&nbsp;Editar
                                            </button>
                                        </div>
                                        <div class="form-group px-2">
                                            <router-link
                                                :to="{ name: 'enunciados.escala.abreviada.desarrollo', params: { id: item.id } }"
                                                class="btn btn-outline-warning "
                                                >
                                               <i class="fas fa-check fa-fw"></i>  Enunciados
                                            </router-link>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";

import 'bootstrap';
import $ from 'jquery';
import Toast from '../../../../../components/common/utilities/toast';
import {required} from "vuelidate/lib/validators";
import rangoEscalaAbreviadaDesarrolloService from "../../../../../services/rangoEscalaAbreviadaDesarrolloService";



export default {
    components: {PageHeaderCompact},
    data(){
        return{
            modificar:true,
            tituloModal:'',
            rango:{
                id:'',
                nombre:'',
                edad_inicio:'',
                tipo_edad_inicio:'',
                edad_final:'',
                tipo_edad_final:'',
            },
            rangos:{},


        }
    },
    validations (){
        return {
            rango:{
                nombre:{required},
                edad_inicio:{required},
                tipo_edad_inicio:{required},
                edad_final:{required},
                tipo_edad_final:{required},
            },

        }
    },
    methods: {
        async abrirModal(data={}){
            $('#modalRango').modal('show');
            if(this.modificar){
                this.rango.id=data.id;
                this.tituloModal="Modificar Rango";
                this.rango.nombre=data.nombre;
                this.rango.edad_inicio=data.edad_inicio;
                this.rango.tipo_edad_inicio=data.tipo_edad_inicio;
                this.rango.edad_final=data.edad_final;
                this.rango.tipo_edad_final=data.tipo_edad_final;
            }else{
                this.id=0;
                this.tituloModal="Crear Rango";
                this.rango.nombre='';
                this.rango.edad_inicio='';
                this.rango.tipo_edad_inicio='';
                this.rango.edad_final='';
                this.rango.tipo_edad_final='';
            }
        },
        async cargarRangos(){
            const response = await rangoEscalaAbreviadaDesarrolloService.index();
            this.rangos=response.data;
        },
        async guardar(){
            try {
                this.$v.rango.$touch();
                if(this.$v.rango.$invalid){
                    return
                }

                if(this.modificar){
                    this.LoaderSpinnerShow();
                    await rangoEscalaAbreviadaDesarrolloService.update(this.rango);
                    this.LoaderSpinnerHide();
                    Toast.fire({
                        icon: 'success',
                        title: 'Datos guardados con exito'
                    });
                    $('#modalRango').modal('hide');
                    this.cargarRangos();

                }else{
                    this.LoaderSpinnerShow();
                    await rangoEscalaAbreviadaDesarrolloService.store(this.rango);
                    this.LoaderSpinnerHide();
                    Toast.fire({
                        icon: 'success',
                        title: 'Datos guardados con exito'
                    });
                    $('#modalRango').modal('hide');
                    this.cargarRangos();
                }
            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
    },
    created(){
        this.cargarRangos();
    }
}
</script>

