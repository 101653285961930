<template>
  <header
    class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
    <div class="container-xl px-4">
      <div class="page-header-content pt-4">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto mt-4">
            <h2 class="page-header-title">
              <slot></slot>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "page-header-compact",
};
</script>

<style scoped></style>
